import { Injectable, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { openDB } from 'idb';
import { StorageService } from "../services/storage.service";
import { environment } from '../../environments/environment';
import { PutObjectCommand,DeleteObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { BehaviorSubject,Subscription,Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { App } from '@capacitor/app';
import { GeoService } from "../services/geo.service";
import { Filesystem, Directory, Encoding, FilesystemEncoding } from '@capacitor/filesystem';
import { Network } from '@capacitor/network';

import {
  Auth,
  getAuth,
  onAuthStateChanged ,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  AuthInstances
} from "@angular/fire/auth";


interface OfflineData {
  data: any[];
  timestamp: number;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnInit {
  private db: Promise<IDBDatabase>;
  private colors = ['#DEF1FC', '#D2FFF0', '#B6FCFE', '#FCF2DE', '#FEFFD2','#EDFFD7','#FCE7DE','#F2F5FF','#FCE4F9','#DEFAFC','#D2FFD4','#F0EEFC'];
  private previousUrl: string = '';
  private currentUrl: string = '';
  private theAuth: any;
  private TeamUsers : any[] = [];
  public isLoading = false;
  userPermissions: any;
  refreshHeader = new Subject<void>();
  private isGetTeamsRunning = false;

  private endpoint = environment.endpoint;
  private apiToken = environment.apiToken;
  private s3_client = new S3Client(environment.S3Config);
  private bucket = environment.BUCKET_NAME;

  public productID: any;
  public teamID: any;
  public ArticleID: any;
  public TeamID: any;
  public statics: any;

  APIRUNNING = false;





  private staticsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  statics$ = this.staticsSubject.asObservable();

  constructor(private http: HttpClient, private router: Router,private auth: Auth,private StorageService:StorageService,private GeoService:GeoService) {
    this.productID = '';
    this.teamID = '';
    this.ArticleID = '';
    this.TeamID = '';
    this.db = this.initializeDatabase();
    this.theAuth = getAuth();





    this.router.events
    .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.urlAfterRedirects;
      console.log('API-previousUrl',this.previousUrl);
    });




  }

  triggerRefresh() {
    this.refreshHeader.next();
  }


  async ngOnInit() {


    this.router.events
    .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.urlAfterRedirects;
      console.log('API2-previousUrl',this.previousUrl);
    });

    
  }



  

  private initializeDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const openreq = indexedDB.open('apiData', 2); // Increase the version number
      openreq.onerror = () => reject(openreq.error);
      openreq.onsuccess = () => resolve(openreq.result);
      openreq.onupgradeneeded = (event) => {
        const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;
        if (!db.objectStoreNames.contains('responses')) {
          db.createObjectStore('responses');
        }
      };
    });
  }

 

async getUID() {
  let uid = await this.StorageService.get('fuid');
  if(!uid){
    const theAuth = getAuth();
    onAuthStateChanged(theAuth, (user) => {
      if (user) {
        uid = user.uid;
        this.StorageService.set('fuid', user.uid);
      }
    });
  }
  return uid || '';
}

async getUName() {
  let uname = await this.StorageService.get('uname');
  return uname || '';
}


async get_statistics(filterBy: any = null) { 
  let filter: any = {"team":await this.getTeamID(),"fuid": await this.getUID()};

  if(filterBy) {
    for(let key in filterBy) {
      if(filterBy[key] !== undefined && filterBy[key] !== null) {
        filter[key] = filterBy[key];
      }
    }
  }

  console.log('get_statistics-->filterBy', filterBy);
  console.log('get_statistics-->filter', filter);
  let statistics = await this.getData('statistics','GET',filter);  // Pass the updated filter object
  this.statics = statistics;  
  return statistics;
}


  /*
    Products
  */

  public async  setProductID(id: any) {
    this.productID = id;
    await this.saveData('productID', id);
  }

  async bulkUpdateProducts(payload: any) {
    let products = await this.getData('teams/move','POST',payload);
    return products;
  }

  async getProducts() {
    let products = await this.getData('products','GET',{"team":this.getTeamID(),"fuid": await this.getUID()});
    return products;
  }

  async getProduct(productBarcode: any) {
    let products = await this.getData('products','GET',{"team":this.getTeamID(),"fuid": await this.getUID()});
    console.log('productBarcode', productBarcode);
    if (productBarcode) {
      products = await products.find((product: { barcode: any; }) => product.barcode == productBarcode);
    }
    return products;
  }


  async saveProduct(payload: any) {
    const status = await Network.getStatus();

    payload.team = this.getTeamID();
    if (payload.hasOwnProperty('_id')) {
      delete payload._id;
    }
    let product: any = payload;
    // if no internet, save it locally then then internet back need to send to api 
    if (!navigator.onLine || !status.connected) {
      console.log('saveProduct-->OFFLINE', payload);
      this.saveData('products', payload);
      this.saveOfflineData('products-offline', payload);
      product =  payload;
    }else{
     product = await this.getData('products','POST',payload);
    }

   
    return product;
  }

  async checkDocuments(modal: any) {
    let result: any = await this.fetchData('https://www.bermad.com/qr_verify/'+modal+'/');
    if (result.qr_verify==true) {
      return true;
    }
    return false;
  }


  async getVersion() {
    let version = await this.getData('version','GET');
    return version;
  }


  async deleteImage(key: any) {
    const command = new DeleteObjectCommand({
      Bucket: this.bucket,
      Key: key,
    });
    try {
    const response = await this.s3_client.send(command);
    return true;
    } catch (err) {
    return false
   }
  
  }

  async uploadProductImage(barcode:any,payload: any) {
    console.log('uploadProductImage');
    payload.team =  await this.getTeamID();
    payload.fuid =  await this.getUID();
    
    payload.barcode = barcode;
    if (payload.hasOwnProperty('_id')) {
      delete payload._id;
    }


    console.log("uploadProductImage-->payload",payload)

    const compressedBase64 = await this.compressImage(payload.image, 0.7);
    const blob = await this.base64ToBlob(compressedBase64, payload.format);
    const filename = 'users/'+payload.team+'/'+this.generateRandomId()+payload.name.payload+'.jpeg';

    console.log('uploadProductImage-->filename',filename);

    
    
    console.log('uploadProductImage-->/command',this.s3_client)
    console.log('S3_CRN',environment.S3Config)
    
    try {
      const command = await new PutObjectCommand({
        Bucket: this.bucket,
        Key: filename,
        Body: blob,
        ContentEncoding: 'base64',
        ContentType: payload.format,
        //Body: payload.image
      });
      const response = await  this.s3_client.send(command);
      return {'url': environment.cloudfront+'/'+filename,'key':filename};
      //await this.sendDatatoAPI('productsImages','POST',{"team":payload.team,"barcode":payload.barcode,"image":{"url":environment.cloudfront+'/'+filename,"key":filename}   });
      //return  environment.cloudfront+'/'+filename;
    } catch (err) {
      console.log('uploadProductImage-->ERROR',err)
     return false
    }

    //let product = await this.sendDatatoAPI('productsImages','POST',payload);
   

  }


  async getBarcode(barcode: any): Promise<any> {
    let product: any = [];
    console.log('Searching for product with SERIAL:', barcode);

    
    if (navigator.onLine) {
        // ONLINE
        product = await this.getData('Barcodes', 'GET', barcode);
        console.log('getBarcode.ONLINE.product', product);
    } else {
     
        // OFFLINE
        const countryName = await this.GeoService.get_country();
        const fileName = countryName + '.json';

        console.log('getBarcode.OFFLINE', fileName);

        try {
          // Parse the decoded string as JSON
          const json = await this.readJsonFromFile(fileName);
          console.log('Searching for product with SERIAL:', barcode.barcode);
          if (Array.isArray(json)) {
              product = await json.find((product: { SERIAL: any }) => product.SERIAL === barcode.barcode);
              if(!product)
                product = [];
              else
              product = [product]
              console.log('getBarcode.OFFLINE.product', product);
          } else {
              console.error('The file does not contain a valid array');
              product = [];
          }
      } catch (error) {
          console.error('Error reading file:', error);
          product = [];
      }
  
    }

    return product;
}


async readJsonFromFile(fileName: string): Promise<any> {
  try {
      const fileData = await Filesystem.readFile({
          path: fileName,
          directory: Directory.External,
          encoding: Encoding.UTF8,
      });

      // Ensure fileData.data is a string
      const fileDataString = typeof fileData.data === 'string' ? fileData.data : await this.blobToString(fileData.data);

      const jsonObject = JSON.parse(fileDataString);

      console.log('Parsed JSON object:', jsonObject);

      return jsonObject;
  } catch (error) {
      console.error('Error reading or decoding the file:', error);
      return undefined;
  }
}





// Function to read and decode JSON from a Base64-encoded file
async readJsonFromBase64File(fileName: string): Promise<any> {
    try {
        // Read the base64 string from the file
        const fileData = await Filesystem.readFile({
            path: fileName,
            directory: Directory.External,
            encoding: Encoding.UTF8,
        });

        // Convert fileData.data to a string
        const fileDataString = typeof fileData.data === 'string' ? fileData.data : await this.blobToString(new Blob([fileData.data]));

        console.log('Base64 file data:', fileDataString);

        // Decode the base64 string to a JSON string
        const jsonString = this.base64ToString(fileDataString);

        console.log('Decoded JSON string:', jsonString);
        
        // Parse the JSON string to a JavaScript object
        const jsonObject = JSON.parse(jsonString);

        console.log('Decoded JSON object:', jsonObject);

        return jsonObject;
    } catch (error) {
        console.error('Error reading or decoding the file:', error);
        return undefined;
    }
}

private async blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsText(blob);
    });
}

// Utility function to decode base64 string to a regular string
private base64ToString(base64: string): string {
    const binaryString = atob(base64);
    return decodeURIComponent(
        Array.from(binaryString)
            .map(char => '%' + char.charCodeAt(0).toString(16).padStart(2, '0'))
            .join('')
    );
}

 


  async to_unit(from_unit: any) {
    let to_unit = await this.getData('to_unit','POST',{from_unit:from_unit});
    return to_unit;
  }


  /*async getProductActivity(barcode: any) {
    const team = this.getTeamID();
    let product = await this.getData('getProductActivity','GET',{"barcode":barcode,"team":team});
    if (product) {
      return product;
    }
    return null;
  }*/


async getServiceLogs(filter:any) {
  let return_data!: any;
  filter.team = this.getTeamID();
  filter.fuid =  await this.getUID()
  
  let serviceLogs = await this.getData('service-log','GET', filter);
  return serviceLogs;
}



async getServiceLog(_id:any) {
  let serviceLogs = await this.getData('service-log','GET',{"team":this.getTeamID(),"fuid": await this.getUID()});
  console.log('getServiceLog._id', _id);
  console.log('getServiceLog.serviceLogs', serviceLogs);
  if (serviceLogs) {

    for (const serviceLog of serviceLogs) {
      console.log(serviceLog);

      if(_id == serviceLog._id){
        serviceLogs = serviceLog;
        break;
    }

    }

    return serviceLogs;

  }

}

async CreatProductServiceLog(payload: any) {
  payload.team = this.getTeamID(); 
  let ServiceLog = await this.getData("service-log",'POST',payload);
  let assignTo: any[] = [];
  for (const user of payload.assignTo) {
    assignTo.push({"fuid":user.uid,"uname":user.uname});
  }

  console.log('ServiceLog', ServiceLog);
  // create notification
  let notification = {
    "fuid":await this.getUID(),
    "uname":await this.getUName(),
    "team":this.getTeamID(),
    "msg":"new_service_log",
    "related":{
      "type": "service",
      "_id": ServiceLog.inserted_id,
      "name": payload.serviceType
    },
    "users":assignTo,
    "users_read": [],
    "d_insert":new Date().getTime()
  }

  await this.sendNotification(notification);


  return ServiceLog;
 }

async CreatServiceLogActivity(payload: any) {
  payload.team = this.getTeamID(); 
  payload.uid = await this.getUID();
  payload.uname = await this.getUName();
  let ServiceLog = await this.getData("service-log",'PUT',payload);

  return ServiceLog;
 }

async UpdateServiceLogActivity(payload: any) {
  payload.team = this.getTeamID(); 
  if(!payload.hasOwnProperty('_id')){
    payload.uid = await this.getUID();
  }else{
    payload.lastUpdate = {
      uid: await this.getUID(),
      uname: await this.getUName(),
      date: new Date()
    }
   
  }
  let ServiceLog = await this.getData("service-log",'POST',payload);
    return ServiceLog;
 }

 async getServiceLogActivity(service_log: any) {
  let activities = await this.getData('service-log-activity','GET',{service_log:service_log});
  return activities;
 }


  /*
    Articles
  */

  public async setArticleID(id: any) {
    this.ArticleID = id;
    await this.saveData('ArticleID', id);
  }

  
  async getArticles() {

    // get current language
    let lang = localStorage.getItem('lang') ?? 'en';
    // get current country
    const countryName = await this.GeoService.get_country();

    let articles = await this.getData('articles','GET',{"lang":lang,"country":countryName});
    return articles;
  }
  async getArticle(ArticleID : any) {

    // get current language
    let lang = localStorage.getItem('lang') ?? 'en';
    // get current country
    const countryName = await this.GeoService.get_country();


    let articles = await this.getData('articles','GET',{"lang":lang,"country":countryName});
    console.log('ArticleID', ArticleID);
    if (articles) {

      for (const article of articles) {
        console.log(article);
  
        if(ArticleID == article._id){
          articles = article;
          break;
      }
  
      }
  
      return articles;
  
    }
    
    return articles;
  }



  /*
    Teams
  */

    private teamIDSource = new BehaviorSubject<string>(this.getTeamID() || '');
    teamID$ = this.teamIDSource.asObservable();

  public  setTeamID(id: any) {
    this.teamID = id;
    localStorage.setItem('teamID', id);
    this.teamIDSource.next(id);
  }

  public getTeamID() {
    let teamID =localStorage.getItem('teamID');
    return teamID;
  }

  async getTeams(payload: any = null) {

    if(this.isGetTeamsRunning){
      return;
    }

    
    let fuid = await this.getUID();
    let uname = await this.getUName();
    let teams: any[] = [];

    this.isGetTeamsRunning = true;
    console.log('getTeams payload', payload);
    if(!payload || payload==null){
      payload = {"fuid":fuid};
    }
    

    payload.fuid = fuid;
    console.log('getTeams payload', payload);
     await this.getData('teams','GET',payload).then((data: any) => {
      console.log('getTeams data', data);
      if(data.length==0){
        console.log('getTeams Empty', data);
        // create team
        this.createTeam({fuid:  this.getUID(),name: uname+" Team",status:"active", image:"",d_insert:new Date().getTime()}).then((team: any) => {
          console.log('create team', team);
          this.setTeamID(team._id);
        });
      }else if(!this.getTeamID() && data.length>0){
        console.log('!getTeamID()', data);
        this.setTeamID(data[0]['_id']);
      }else if(this.getTeamID() && data.length>0){
        let team = data.find((team: { id: any; }) => team.id == this.getTeamID());
        if(!team){
          console.log('getTeamID()!=his.getTeamID()', this.getTeamID());
          this.setTeamID(data[0]['_id']);
        }
      }

      teams = data;
      return data;
    });


    this.isGetTeamsRunning = false;

    console.log('teams', teams);
    console.log('this.TeamID', this.TeamID);
    if (this.TeamID) {
      return teams.find((team: { id: any; }) => team.id == this.TeamID);
    }
    return teams;
  }

  async createTeam(payload: any) {
    if(this.isGetTeamsRunning){
      return;
    }
    payload.fuid = await this.getUID();
    payload.uname = await this.getUName();
    payload.d_insert = new Date().getTime();
    payload.status = 'active';
    if(!payload.hasOwnProperty('image')){
      payload.image = '';
    }
    
    
    let team = await this.getData('teams','POST',payload);
    return team;
  }

  async updateTeam(payload: any) {
    payload.fuid = await this.getUID();
    payload.uname = await this.getUName();
    payload.d_update = new Date().getTime();
    payload.status = 'active';
    if(!payload.hasOwnProperty('image')){
      payload.image = '';
    }
    let team = await this.getData('teams','PUT',payload);
    return team;
  }

  async getTeamUsers() {
    let users = [];
    let teamID = this.getTeamID();
    //let teamUsers = await this.getData('teams','GET',{"_id":teamID});
    let teamUsers = await this.getData('teams/invite','GET',{"team":teamID});
    if (teamUsers) {
      for (let index = 0; index < teamUsers.length; index++) {
        const user = teamUsers[index];
        if(user['status']=== true )
        users.push({'uid':user['fuid'],'fuid':user['fuid'],'uname':user['uname'] || user['email'],'fcm':user['fcm'],'permissions':user['permissions']});
        console.log("getTeamUsers",user);
        if ( user['fuid']== await this.getUID() ) {
          this.userPermissions = user['permissions'];
        }

      }
    }
    //console.log('getTeamUsers->teamUsers', teamUsers);
    //console.log('getTeamUsers->users', users);
    return users;
  }

  async getUserPermissions() {
    if(!this.userPermissions){
      let users = await this.getTeamUsers();
      let user = users.find((user: { uid: any; }) => user.uid === this.getUID());
      console.log('getUserPermissions-->user', user);
      if (user) {
        this.userPermissions = user.permissions;
      }
    }
    return this.userPermissions;
  }

  async canEdit() {
  let permissions = await this.getUserPermissions();
  console.log('canEdit-->permissions', permissions);
    if(permissions && permissions.length>0){
      return !permissions.includes('view_only');
      //return !permissions.includes('view_only') && ( !permissions.includes('view_reports') );
    }else{
      return false;
    }
  }

  async inviteUser(payload: any) {
    payload.d_update = new Date().getTime();
    payload.d_insert = new Date().getTime();
    let response = await this.getData('teams/invite','POST',payload);
    return response;
  }

  async inviteUserUpdate(payload: any) {
    payload.d_update = new Date().getTime();
    let response = await this.getData('teams/invite','PUT',payload);
    return response;
  }

  async inviteUserDelete(payload: any) {
    let response = await this.getData('teams/invite','DELETE',payload);
    return response;
  }

  async getInvitedUsers(payload: any) {
    let response = await this.getData('teams/invite','GET',payload);
    return response;
  }


  /*
    Projects
  */

    async createProject(payload: any) {
      payload.team = this.getTeamID();
      payload.fuid = await this.getUID();
      payload.uname = await this.getUName();
      payload.d_insert = new Date().getTime();
      payload.status = 'active';
      if(!payload.hasOwnProperty('image')){
        payload.image = '';
      }
      let team = await this.getData('Projects','POST',payload);
      return team;
    }

    async updateProject(payload: any) {
      payload.team = this.getTeamID();
      payload.update_fuid = await this.getUID();
      payload.update_uname = await this.getUName();
      payload.d_update = new Date().getTime();
      payload.status = 'active';
      if(!payload.hasOwnProperty('image')){
        payload.image = '';
      }
      let team = await this.getData('Projects','PUT',payload);
      return team;
    }

    async getProjects() {
      let projects = await this.getData('Projects','GET',{"team":this.getTeamID(),"fuid": await this.getUID()});
      return projects;
    }


  /*
    hubspotContact
    not in use
  */

  async hubspotContact(data: any) {
    console.log('hubspotContact', data);
    const url = this.endpoint+'hubspotContact';
    const headers = { 'Content-Type': 'application/json','X-API-Key': this.apiToken };
    const body = JSON.stringify(data);
    const response = await fetch(url, { method: 'POST', headers, body,redirect: 'follow' });
    const json = await response.json();
    console.log('hubspotContact', json);

    
  }


  async contact_us(data:any){
    let payload = data
    payload.teamid = this.getTeamID();
    payload.fuid = await this.getUID();

    let lowerCaseKeysObj: { [key: string]: any } = Object.entries(data).reduce((newObj, [key, value]) => {
      newObj[key.toLowerCase()] = value;
      return newObj;
    }, {} as { [key: string]: any });

    console.log('contact_us', data);
    

    if(payload.hasOwnProperty('action')){
      payload.sync_id = payload.action;
    }else{
      payload.sync_id = 'contact_us';
    }
    

    const url = this.endpoint+'contact-us';
    const headers = { 'Content-Type': 'application/json','X-API-Key': this.apiToken };
    const body = JSON.stringify(payload);
    const response = await fetch(url, { method: 'POST', headers, body,redirect: 'follow' });
    const json = await response.json();

    let hubspot_payload = {"properties":lowerCaseKeysObj}
    //this.hubspotContact(hubspot_payload);
    console.log('contact_us', json);
  }


  /*
  notifications
  */

  async sendNotification(payload: any) {
    payload.update_fuid = await this.getUID();
    payload.update_uname = await this.getUName();
    payload.d_insert = new Date().getTime();
    let notification = await this.getData('notifications','POST',payload);
    return notification;
  }

  async markNotificationAsRead(payload: any) {
    payload.fuid = await this.getUID();
    payload.read_date = new Date().getTime();
    let notification = await this.getData('notifications','PUT',payload);
    return notification;
  }


  async getNotifications(payload: any) {
    let notifications = await this.getData('notifications','GET',payload);
    return notifications;
  }



  async registerUser(payload: any) {
    await this.getData('user_update_insert','POST',payload).then((data: any) => {
      let user = data; // Move this line here
      let displayName = payload.firstName +' '+ payload.lastName;
      this.createTeam({fuid: payload.fuid,name: displayName+" Team",status:"active", image:"",d_insert:new Date().getTime()}).then((team: any) => {
        this.setTeamID(team._id);
      });
  
      this.StorageService.set('fuid', data.fuid);
      this.StorageService.set('uname', data.uname);
      this.StorageService.set('email', data.email);
      this.StorageService.set('phone', data.phone);
      this.StorageService.set('CountryCode', data.CountryCode);
      this.StorageService.set('country', data.country);
      this.StorageService.set('company', data.company);
      return user; // Move this line here
    });
  }

  async china_users_register(payload: any) {
    let user = await this.getData('china_users_register','POST',payload);
    return user;
  }

  async china_users_register_verification(payload: any) {
    let user = await this.getData('china_users_register_verification','POST',payload);
    return user;
  }

  async china_users_register_update(payload: any) {
    let user = await this.getData('china_users_register','PUT',payload);
    return user;
  }


  async china_users_login(payload: any) {
    let user = await this.getData('china_users_login','POST',payload);
    return user;
  }

  async china_users_reset_password(payload: any) {
    let user = await this.getData('china_users_reset_password','POST',payload);
    return user;
  }
  async china_users_reset_password_check_token(payload: any) {
    let user = await this.getData('china_users_reset_password_check_token','POST',payload);
    return user;
  }

  async china_users_reset_password_update(payload: any) {
    let user = await this.getData('china_users_reset_password','PUT',payload);
    return user;
  }

  

  /*
    API Calls
  */


    async sendDatatoAPI(key: string, method: string = 'POST', payload: any = null) {
      //const status = await Network.getStatus();
      if(!this.APIRUNNING && navigator.onLine){
        this.APIRUNNING = true;
        const url = this.endpoint + key;
        const headers = { 'Content-Type': 'application/json', 'X-API-Key': this.apiToken };
        const body = JSON.stringify(payload);
        const response = await fetch(url, { method: method, headers, body, redirect: 'follow' });
        const json = await response.json();
        console.log('sendDatatoAPI', json);
        this.APIRUNNING = true;

        this.clearCache(key+'null');
        this.clearCache(key+this.getTeamID());
        return json;
      }

    }


  async fetchData(url: string) {
    // Fetch data from the API
    const dataPromise = this.http.get(url);
    const data = await dataPromise.toPromise();

    // Save the data to IndexedDB
    await this.saveData(url, data);

    return data;
  }

  async saveOfflineData(key: string, newData: any): Promise<void> {
    const db = await this.db;

    try {
        // Start a transaction and get the store
        const tx = db.transaction('responses', 'readwrite');
        const store = tx.objectStore('responses');
        const timestamp = new Date().getTime();

        // Get existing data within the transaction
        const existingData = await new Promise<OfflineData | undefined>((resolve, reject) => {
            const getRequest = store.get(key);
            getRequest.onsuccess = () => resolve(getRequest.result as OfflineData);
            getRequest.onerror = () => reject(getRequest.error);
        });

        let updatedData: OfflineData;
        console.log('saveOfflineData->existingData', existingData);

        if (existingData && Array.isArray(existingData.data)) {
            // If existing data is found and is an array, append new data to it
            updatedData = { ...existingData, data: [...existingData.data, newData], timestamp };
        } else {
            // If no existing data or existing data is not an array, create new data structure
            updatedData = { data: [newData], timestamp };
        }

        console.log('saveOfflineData->updatedData', updatedData);

        // Put (update or insert) the updated data back into the store within the transaction
        await new Promise<void>((resolve, reject) => {
            const putRequest = store.put(updatedData, key);
            putRequest.onsuccess = () => resolve();
            putRequest.onerror = () => reject(putRequest.error);
        });

        return new Promise<void>((resolve, reject) => {
            tx.oncomplete = () => resolve();
            tx.onerror = () => reject(tx.error);
            tx.onabort = () => reject(tx.error);
        });
    } catch (error) {
        console.error('Failed to save data:', error);
        throw error; // Rethrow or handle error as needed
    }
}




  async saveData(key: string, data: any) {
    const db = await this.db;
    const tx = db.transaction('responses', 'readwrite');
    const store = tx.objectStore('responses');
    const timestamp = new Date().getTime();
    store.put({data, timestamp}, key);
    return new Promise<void>((resolve, reject) => {
      tx.oncomplete = () => resolve();
      tx.onerror = () => reject(tx.error);
    });
  }

  async getTheSavedData(key: string) {
    const db = await this.db;
    const tx = db.transaction('responses', 'readonly');
    const store = tx.objectStore('responses');
    const request = store.get(key);
    return new Promise<any>((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  }
  
  async getSavedData(key: string) {
    const db = await this.db;
    const tx = db.transaction('responses', 'readonly');
    const store = tx.objectStore('responses');
    const request = store.get(key);
    return new Promise<any>((resolve, reject) => {
      request.onsuccess = () => {
        const data = request.result;
        if (data) {
          const now = new Date().getTime();
          const lifetime = 5 * 60 * 1000; // 5 minutes in milliseconds
          if (now - data.timestamp < lifetime) {
            resolve(data.data);
          } else {
            // Data is expired, delete it
            const deleteTx = db.transaction('responses', 'readwrite');
            const deleteStore = deleteTx.objectStore('responses');
            deleteStore.delete(key);
            deleteTx.oncomplete = () => resolve(null);
            deleteTx.onerror = () => reject(deleteTx.error);
          }
        } else {
          resolve(null);
        }
      };
      request.onerror = () => reject(request.error);
    });
  }
  
  async clearCache(key: string) {
    const db = await this.db;
    const tx = db.transaction('responses', 'readwrite');
    const store = tx.objectStore('responses');
    store.delete(key);
    return new Promise<void>((resolve, reject) => {
      tx.oncomplete = () => resolve();
      tx.onerror = () => reject(tx.error);
    });
  }

  async clearCacheAll() {
    const db = await this.db;
    const tx = db.transaction('responses', 'readwrite');
    const store = tx.objectStore('responses');
    
    // Open a cursor to iterate over all entries in the object store
    const cursorRequest = store.openCursor();
  
    cursorRequest.onsuccess = (event) => {
      const cursor = cursorRequest.result;
      if (cursor) {
        // Delete the current entry pointed to by the cursor
        store.delete(cursor.key);
        // Move to the next entry
        cursor.continue();
      } else {
        // All entries have been processed
        tx.oncomplete = () => console.log('Cache cleared successfully');
      }
    };
  
    cursorRequest.onerror = (event) => {
      console.error('Error iterating over cache entries:', cursorRequest.error);
    };
  
    return new Promise<void>((resolve, reject) => {
      tx.onerror = () => reject(tx.error);
    });
  }
  

  async getData(key: string, method: string = 'POST', payload: any = null) {
    const savedKey = key+this.getTeamID();
    const status = await Network.getStatus();
    console.log('--- NETWORK ---', status);
    console.log('--- KEY ---', key);
    console.log('savedKey', savedKey);

    if(key=='offline'){
      console.log('--- /KEY ---', key);
      return;
    }



    if (navigator.onLine) {
      // ONLINE
      console.log('ONLINE');
      try {
       
        let response: any;
        const url = this.endpoint + key;
        const headers = { 'Content-Type': 'application/json', 'X-API-Key': this.apiToken };
        if (method == 'GET') {

          let TheSavedData = await this.getTheSavedData(savedKey);
          if (TheSavedData && TheSavedData.data && TheSavedData.data.length > 0) {
            console.log('return_data-->API-->CACHE', TheSavedData);
           // return TheSavedData.data;
          }

          console.log('GET', new URLSearchParams(payload).toString());
          response = await fetch(url + '?' + new URLSearchParams(payload).toString(), { method: method, headers, redirect: 'follow' });
        } else {
          const body = JSON.stringify(payload);
          response = await fetch(url, { method: method, headers, body, redirect: 'follow' });
          //await this.clearCache(savedKey);
        }

        if (!response.ok) {
          console.error(`HTTP error! status: ${response.status}`);
        } else {
          let return_data = await response.json();
          if (!return_data) {
            console.error('No data returned from the API');
          } else {

            // save for cache 
            console.log('return_data-->API', return_data);
            if (method == 'GET') {
              await this.saveData(savedKey, return_data); 
            }
            return return_data;
          }
        }
      } catch (error) {
        // Fetching from the network failed, so return the cached data
        console.error('There was a problem with the fetch operation: ', error);
      }

    }else{
      // OFFLINE 
      console.log('OFFLINE');
     
      let TheSavedData = await this.getTheSavedData(savedKey);
      let dataTOSave = { "method": method, "payload": payload, "key": key, "team": this.getTeamID(), "fuid": await this.getUID() };
      let existingDataOffline = await this.getTheSavedData('offline');

      if (TheSavedData) {
        console.log(key + '-->SavedData', JSON.stringify(TheSavedData));
        if (method == 'GET') {
          console.log('return_data-->CACHE', TheSavedData);
          return TheSavedData.data;
        }else{
         
          // save the request to send it to api when online
          if(existingDataOffline && existingDataOffline.data.length > 0){
            existingDataOffline.data.push(dataTOSave);
          }else{
            existingDataOffline.data = [dataTOSave];
          }
          await this.saveData('offline', existingDataOffline.data);

          // check if element exists in the array
          if(TheSavedData){
            console.log('return_data-->OFFLINE-->TheSavedData', JSON.stringify(TheSavedData));
            console.log('return_data-->OFFLINE-->TheSavedData', TheSavedData);

            /*for (let index = 0; index < TheSavedData.data.length; index++) {
              const element = TheSavedData.data[index];
              console.log('return_data-->OFFLINE-->TheSavedData-->element', element);
              if (element._id === payload._id) {
                TheSavedData.data[index] = dataTOSave;
              }else{
                TheSavedData.data.push(dataTOSave);
              }
            }*/
            TheSavedData = TheSavedData.data;
          }else{
            TheSavedData = [payload];
          }

         
          await this.saveData(savedKey, TheSavedData);
          console.log('return_data-->CACHE', TheSavedData);
          return TheSavedData.data;
          
          
        }
      }



    }
    console.log('--- /KEY ---', key);
  }

  /*
  async getData(key: string, method: string = 'POST', payload: any = null) {
    let return_data!: any;
    this.isLoading = false;
    console.log('payload key', payload + ' ' + key);
    const savedKey = key+this.getTeamID();
    
    if (method=='GET' ) {
      console.log('key', key);
      console.log('savedKey', savedKey);
      const value = await this.getTheSavedData(savedKey);
      if (value && value !== undefined && value !== null && value !== 'undefined' && value !== 'null' && value !== '[]' && value !== '{}' && value !== ''  && value.length > 0)  {
        console.log(key+'-->SavedData', JSON.stringify(value)); 
        return_data = value;
        this.isLoading = false;
        return return_data;
      }
    } else if ( (method == 'POST' || method == 'PUT' || method == 'DELETE') && navigator.onLine ) {
      await this.clearCache(savedKey);
      await this.clearCache('notifications'+this.getTeamID()+'fuid-team');
    }
  
    try {
      if (navigator.onLine) {
        let response :any;
        const url = this.endpoint + key;
        const headers = { 'Content-Type': 'application/json', 'X-API-Key': this.apiToken };
        if(method == 'GET') { 
          console.log('GET', new URLSearchParams(payload).toString());
          response = await fetch(url+'?'+new URLSearchParams(payload).toString(), { method: method, headers, redirect: 'follow' });
        } else {
          const body = JSON.stringify(payload);
          response = await fetch(url, { method: method, headers, body, redirect: 'follow' });
        }
  
        if (!response.ok) {
          console.error(`HTTP error! status: ${response.status}`);
        } else {
          return_data = await response.json();
          if (!return_data) {
            console.error('No data returned from the API');
          } else {

              if (method == 'GET') {
                // Save the data to IndexedDB for offline use
                await this.saveData(savedKey, return_data); 
              }
          }
        }
      }else{
        console.log('offline');
        if(method=='POST' || method=='PUT' || method=='DELETE'){
          // save new data or update data to send it to api when online
          payload.synced = false;
          let existingData = await this.getData('offline');
          let theSavedData = await this.getTheSavedData(savedKey);
          let dataTOSave = {"method":method,"payload":payload,"key":key,"team":this.getTeamID(),"fuid":await this.getUID()};

          if (typeof existingData !== 'undefined'  && existingData.length > 0) {
            existingData.push(dataTOSave);
          }else{
            existingData = [dataTOSave];
          }
          
          await this.saveData('offline'+savedKey, existingData);

          if (theSavedData) {
            // check if element exists in the array
            console.log('theSavedData', theSavedData);
            console.log('payload', payload);
            
            let index = theSavedData.data.findIndex((element: { _id: string }) => element._id === payload._id);
            if (index > -1) {
              theSavedData.data[index] = dataTOSave;
            }else{
              theSavedData.data.push(dataTOSave);
            }
          } else {
            theSavedData = [payload];
          }
          await this.saveData(savedKey, theSavedData);




        }else{
          const value = await this.getSavedData(savedKey);
          if (value !== undefined && value !== null) {
            console.log(key+'-->SavedData', JSON.stringify(value)); 
            return_data = value;
            return return_data
            }
        }
      }
    } catch (error) {
      // Fetching from the network failed, so return the cached data
      console.error('There was a problem with the fetch operation: ', error);
    }
  
    if (!return_data) {
      console.log('The data was not returned from the API, so will need to be retrieved from IndexedDB');
      const db = await this.db;
      if (db) {
        console.log('The DB is open');
        const transaction = db.transaction('responses');
        if (transaction.objectStoreNames.contains('responses')) {
          return new Promise((resolve, reject) => {
            const request = transaction.objectStore('responses').get(key);
            request.onsuccess = function(event) {
              resolve(request.result);
            };
            request.onerror = function(event) {
              reject(new Error('Error fetching data from IndexedDB'));
            };
          });
        } else {
          console.error('The "responses" object store does not exist');
        }
      } else {
        console.error('The database connection is not open');
      }
    }
    this.isLoading = false;
    return return_data;
  }*/

  /*
    private pendingRequests = new Map();
  
    async getData(key: string, method: string = 'POST', payload: any = null) {
      const requestKey = JSON.stringify({ key, method, payload });
      
      // If the same request is already pending, wait for it to complete
      if (this.pendingRequests.has(requestKey)) {
        return await this.pendingRequests.get(requestKey);
      }
  
      const request = this.sendRequest(key, method, payload);
      this.pendingRequests.set(requestKey, request);
  
      // Once the request completes, remove it from the pending requests
      const result = await request;
      this.pendingRequests.delete(requestKey);
      return result;
    }
  
    private async sendRequest(key: string, method: string, payload: any) {
      let return_data!: any;
      this.isLoading = true;
      console.log('payload key', payload + ' ' + key);
      try {
        if (navigator.onLine) {
          let response: any;
          const url = this.endpoint + key;
          const headers = { 'Content-Type': 'application/json', 'X-API-Key': this.apiToken };
          if (method == 'GET') {
            console.log('GET', new URLSearchParams(payload).toString());
            response = await fetch(url + '?' + new URLSearchParams(payload).toString(), { method: method, headers, redirect: 'follow' });
          } else {
            const body = JSON.stringify(payload);
            response = await fetch(url, { method: method, headers, body, redirect: 'follow' });
          }
  
          if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
          } else {
            return_data = await response.json();
            if (!return_data) {
              console.error('No data returned from the API');
            } else {
              // Save the data to IndexedDB for offline use
              await this.saveData(key, return_data);
              this.cache.set(key, return_data)
              this.isLoading = false;
            }
          }
        }
      } catch (error) {
        // Fetching from the network failed, so return the cached data
        console.error('There was a problem with the fetch operation: ', error);
      }
  
      if (!return_data) {
        console.log('The data was not returned from the API, so will need to be retrieved from IndexedDB');
        const db = await this.db;
        if (db) {
          console.log('The DB is open');
  
          const transaction = db.transaction('responses');
          if (transaction.objectStoreNames.contains('responses')) {
            return new Promise((resolve, reject) => {
              const request = transaction.objectStore('responses').get(key);
              request.onsuccess = function (event) {
                resolve(request.result);
              };
              request.onerror = function (event) {
                reject(new Error('Error fetching data from IndexedDB'));
              };
            });
          } else {
            console.error('The "responses" object store does not exist');
          }
        } else {
          console.error('The database connection is not open');
        }
      }
      
     
      return return_data;
    }*/
  


// Function to get a color for a user
getColorForUser(id: any) {
  // Use the hash of the ID to determine the color
  const colorIndex = Math.abs(this.getHash(id)) % this.colors.length;
  return this.colors[colorIndex];
}

getAvataForUser(fullName: any) {
  // Use the hash of the ID to determine the color
  let nameParts = fullName.split(' ');
  let avatar: string; // Declare the 'avatar' variable
  if (nameParts.length > 1) {
    avatar = nameParts[0][0] + nameParts[nameParts.length - 1][0];

  } else {
    avatar = fullName[0];
  }
  return avatar;
}

// Function to get a hash from a string
getHash(str:any) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = ((hash << 5) - hash) + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

  getPreviousUrl() {
    return this.previousUrl;
  }


 generateRandomId() {
    return String(Math.floor(Math.random() * 100000)).padStart(5, '0');
  }


  base64ToBlob(base64: string, contentType: string) {
    // Remove the data URL scheme prefix if it exists
    const base64Data = base64.split(',')[1] || base64;
  
    const sliceSize = 512;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
  
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
  
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  async  compressImage(base64: string, quality: number) {
    const img = new Image();
    img.src = base64;
  
    await new Promise((resolve) => img.onload = resolve);
  
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
  
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0, img.width, img.height);
  
    return canvas.toDataURL('image/jpeg', quality);
  }


  generate_uniqID(){
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
  deleteEmptyKeys(obj: any) {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key];
      }
    }
    return obj;
  }

  async getAppInfo() {
    const info = await App.getInfo();
    console.log('App Version: ', info.version);
    return info;
  }

}