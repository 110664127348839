import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from "../services/storage.service";



@Injectable({
  providedIn: 'root'
})
export class GeoService {
  constructor(private geolocation: Geolocation,private http: HttpClient, private StorageService: StorageService) { 
  }

  async getCurrentPosition() {
    let cords: any;
    try {
      const coordinates = await Geolocation.getCurrentPosition({ enableHighAccuracy: true, timeout: 5000, maximumAge: 0 });
      cords = coordinates.coords;
      cords.zoom = 8;
      console.log('Current position:', coordinates);
      this.StorageService.set('cords',cords)
    } catch (e) {
      console.log('Error getting location', e);
      const country = await this.StorageService.get('country');
      const get_cords = await this.StorageService.get('cords');
      if (get_cords) {
        cords = get_cords;
      } else {

      cords = await new Promise((resolve, reject) => {
        this.http.get('https://nominatim.openstreetmap.org/search?format=json&q=' + encodeURIComponent(country)).subscribe((response: any) => {
          if (response && response[0]) {
            let latitude = response[0].lat;
            let longitude = response[0].lon;
            let result = { latitude: latitude, longitude: longitude, accuracy: 0,zoom:4 };
            this.StorageService.set('cords',result)
            console.log('cords', result);
            resolve(result);
          } else {
            reject('No response from Nominatim');
          }
        }, error => {
          reject(error);
        });
      });

      }
    }
    console.log('cords', cords);
    return { coords: cords };
  }

  getCountryName(latitude: number, longitude: number): Observable<string> {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=en`;

    return this.http.get(url).pipe(
      map((response: any) => {
       console.log(response);
        if (!response.results || response.results.length === 0) {
          //throw new Error('No results found');
        }
        console.log("getCountryName",JSON.stringify(response));
        return response.address.country;
        /*
        const addressComponent = response.results.find((component: any) =>
          component.types.includes('country')
        );
       
        return addressComponent ? addressComponent.formatted_address        : 'Country not found';
        */
      }),
      catchError((error) => {
        console.error('Error fetching country name:', error);
        // Return a default value or an Observable that emits an error
        return of('Country not found');
      })
    );
  }


  async get_country(offline=false) {
    let country = await this.StorageService.get('country');
    const cords = await this.StorageService.get('cords');
    this.getCurrentPosition();

    //const accessToken = 'your_access_token'; 
    if (navigator.onLine && !offline) {

      try {
      // If the user is connected to the internet, use the ipapi.co API
      const response = await fetch(`https://pro.ip-api.com/json/?key=umkcsNBIeeDOi8i`);
      const data = await response.json();
      country = data.country;
      this.StorageService.set('country', country);
      this.StorageService.set('cords', { latitude: data.lat, longitude: data.lon, accuracy: 0,zoom:4 });
      }catch (error) {
        console.error('Error fetching country from ipapi.co:', error);
        country = this.get_country(true);
      }

    } else if (country) {
      // If the user is not connected to the internet and the country is stored in the storage, use the stored country
      return country;
    } else if (cords) {
      // If the user is not connected to the internet and the country is not stored in the storage, but the coordinates are available, use the coordinates to get the country
      // This is just a placeholder. Replace it with your actual logic.
      country = this.getCountryName(cords.latitude, cords.longitude).toPromise();
      this.StorageService.set('country', country);
    }
    return country;
  }






  public async enterVerificationCode(confirmationResult:any,code:any) {
    return new Promise<any>((resolve, reject) => {
      confirmationResult.confirm(code).then(async (result:any) => {
        console.log(result);
        const user = result.user;
        resolve(user);
      }).catch((error:any) => {
        reject(error.message);
      });

    });
  }



}
