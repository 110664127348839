import { Component, OnInit } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  getAdditionalUserInfo,
  createUserWithEmailAndPassword,
  signOut,
} from "@angular/fire/auth";
import { getAuth } from 'firebase/auth';
import { AnalyticsService } from'../../services/analytics.service';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent  implements OnInit {
  version: string = '';
  constructor(private analyticsService: AnalyticsService,private ApiService:ApiService,private router: Router) { 
    /*
    const  theAuth = getAuth()
    onAuthStateChanged(theAuth, (user) => {
      console.log(user);
      if (user) {
       console.log('User is  logged in');
       this.analyticsService.setUserId(user.uid); 
     

       router.navigate(['/tabs/home']);
      } else {
        console.log('User is not logged in');
        //router.navigate(['/login']);
      }
    });*/

  }

  ngOnInit() {
    this.ApiService.getAppInfo().then((info: any) => {
      console.log('getAppInfo',JSON.stringify(info));
      this.version = info.version;
    });
  }

}
