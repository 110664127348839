import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
    providedIn: 'root'
  })
  export class TranslateConfigService {
  
    currentLang: any;
  
    constructor(
      private translate: TranslateService,
    ) {
      this.currentLang = localStorage.getItem('lang') || this.getDeviceLanguage() || this.getDefaultLanguage() || 'en';
    }


    getDeviceLanguage(): string {
      // Attempt to get the browser's language
      let deviceLang = this.translate.getBrowserLang();
      console.log('deviceLang', deviceLang);  
      // If the browser's language is undefined or not among the supported languages, fallback to 'en'
      if (!deviceLang || !this.translate.getLangs().includes(deviceLang)) {
        deviceLang = 'en'; // Fallback language
      }

      if(deviceLang == 'en' || deviceLang == 'es'){
      this.setLanguage(deviceLang);
      }
      return deviceLang;
    }
  
    getDefaultLanguage(){

      if (this.currentLang) {
        this.translate.setDefaultLang(this.currentLang);
      } else {
        localStorage.setItem('lang', this.translate.getBrowserLang()!);
        this.currentLang = this.translate.getBrowserLang();
        this.translate.setDefaultLang(this.currentLang);
      }
      /*
      this.currentLang = 'en';
      localStorage.setItem('lang', this.currentLang);
      this.translate.setDefaultLang(this.currentLang);
      */
      return this.currentLang;
    }
  
    setLanguage(setLang: string) {
      this.translate.use(setLang);
      localStorage.setItem('lang', setLang);
    }
  
    getCurrentLang() {
      return localStorage.getItem('lang') || this.getDefaultLanguage() || 'en';
    }


    
  
  }