import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
// https://github.com/ionic-team/ionic-storage

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storageChange: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  constructor(private _storage: Storage,private storage: Storage) { 
    this._storage.create();
    
  }

  async init() {
    this._storage = await this.storage.create();
  }


  public set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  public async get(key: string): Promise<any> {
    if(!this._storage)
    await this.init() ;

    return this._storage.get(key).then((val) => { // <-- Here!
      
      return val;
    });

  }

  public async remove(key: string): Promise<void> {
    await this._storage?.remove(key);
  }

  public async clear() : Promise<void>{
    await this._storage?.clear();
  }

  public onStorageChange() {
    return this.storageChange.asObservable();
  }

}
