<div class="loading-overlay" *ngIf="isLoading" style="flex-direction:column">
  <ion-spinner></ion-spinner>
  <br>
  {{downloadProgress}}%
  <br>
  <progress [value]="downloadProgress" max="100"></progress>
  

  </div>

<ion-popover-view style="padding-bottom: 10px;">

  <div>
 



      <ion-item lines="none" style="--min-height:auto;margin-top: 5px;margin-bottom: 0px;" slot="end">
        <ion-button (click)="closeModalController()" slot="end" class="btn-clear"><img  src="../../assets/icons/x.svg"  /></ion-button>
      </ion-item>
      
      
        <h1 class="ion-text-center">{{"UpdateAvailable" | translate}}</h1>
        
        <p  class="ion-text-center popupTitle ion-padding-horizontal">{{"UpdateAvailableMsg" | translate}}</p> 
        <p  class="ion-text-center popupTitle ion-padding-horizontal">
          <img  src="../../assets/icons/updateApp.svg"  />
        </p> 


  

        <ion-row class="ion-margin-horizontal  ">
          <ion-col size="6" class="ion-text-center">
            <ion-button class="btn-sky-blue"  (click)="UpdateNow()"> {{"Install" | translate}}</ion-button>
          </ion-col>
          <ion-col size="6" class="ion-text-center">
            <ion-button   class="btn-white"  (click)="closeModalController()"> {{"Later" | translate}}</ion-button>
          </ion-col>
        </ion-row> 

          


  </div>


</ion-popover-view>
