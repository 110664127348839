<div class="splash-screen">



  



  <div class="wave" style="display: flex;align-items: center; justify-content: center;">
 
    <div class="splashlogo">

      
<svg width="165" height="200" viewBox="0 0 165 200" fill="none" xmlns="http://www.w3.org/2000/svg" class="splash-image breathing"  style="width: 100px;">
  <mask id="mask0_348_2443" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="165" height="200">
  <path d="M164.883 0H0V200H164.883V0Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_348_2443)">
  <path d="M124.23 166.747C115.235 166.747 111.989 174.485 111.989 179.063V199.954H121.947V190.007L126.467 190.019V199.948H136.425V179.057C136.425 174.485 133.23 166.741 124.23 166.741M126.473 181.719H121.953V178.827C121.953 177.153 122.865 176.172 124.19 176.172C125.515 176.172 126.473 177.153 126.473 178.827V181.719Z" fill="white"/>
  <path d="M39.0352 191.551C37.6011 191.551 36.4366 190.386 36.4366 188.952C36.4366 187.518 37.6011 186.354 39.0352 186.354H46.389V183.359L46.3948 179.843H39.0352C37.6011 179.843 36.4366 178.678 36.4366 177.244C36.4366 175.81 37.6011 174.646 39.0352 174.646H46.4177V166.85H36.798C31.1363 166.85 26.467 168.789 26.467 176.384C26.467 180.049 28.5895 181.77 30.9528 183.354H30.947C28.578 184.948 26.4556 186.663 26.4556 190.335C26.4556 197.924 31.0905 199.943 36.7521 199.943H46.3776L46.4177 191.556H39.0409L39.0352 191.551Z" fill="white"/>
  <path d="M107.813 169.529C106.264 167.693 104.033 166.776 101.124 166.776C99.2888 166.776 97.7515 167.126 96.501 167.831C95.2562 168.537 94.0803 169.707 92.9904 171.353C91.2064 168.301 88.6538 166.776 85.3325 166.776C82.5332 166.776 80.2616 167.693 78.5006 169.529C76.7453 171.365 75.8677 173.728 75.8677 176.619V199.954H85.0858V177.571C85.0858 176.602 85.8774 175.81 86.8469 175.81C87.8163 175.81 88.6079 176.602 88.6079 177.571V199.954H97.3729V177.571C97.3729 176.602 98.1645 175.81 99.1339 175.81C100.103 175.81 100.895 176.602 100.895 177.571V199.954H110.13V177.451C110.13 174.003 109.356 171.365 107.807 169.529" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21.3906 182.235C22.8992 180.508 23.4499 178.919 23.4499 176.682C23.4499 170.384 17.8743 166.753 12.2126 166.753C4.81285 166.753 0.0632324 171.135 0.0632324 177.669V199.96H9.72881V178.151V178.042C9.72881 176.814 10.7269 175.816 11.9602 175.816H12.0692C13.3484 175.827 14.3752 176.866 14.3752 178.139C14.3752 179.413 13.3369 180.462 12.052 180.462C11.8512 180.462 11.7193 180.462 11.4898 180.422V186.353H12.6428C14.0826 186.353 15.2413 187.518 15.2413 188.952C15.2413 190.386 14.0826 191.55 12.6428 191.55H11.4898V199.954H15.4995C20.9776 200 25.4576 196.552 25.4576 190.208C25.4576 186.571 24.0866 183.916 21.3906 182.235Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M69.7413 183.491C71.7375 181.908 72.9249 179.2 72.9249 176.728C72.9249 170.619 66.7011 166.741 60.9935 166.741C52.9685 166.741 48.3049 171.221 48.3049 179.338V199.948H58.5269V178.139V178.03C58.5269 176.803 59.525 175.804 60.7526 175.804H60.8673C62.1465 175.816 63.1733 176.848 63.1733 178.128C63.1733 179.407 62.135 180.451 60.8501 180.451C60.6493 180.451 60.4887 180.451 60.288 180.422V188.591C62.548 188.648 63.0356 189.537 63.0356 191.791V199.948H73.3953V190.157C73.3953 186.887 72.4431 184.466 69.7356 183.48" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M148.368 166.873H138.272V199.954H148.23V176.2C153.026 176.2 154.626 179.929 154.626 183.199C154.626 186.927 153.141 189.761 150.502 190.461V199.862C159.491 199.386 164.762 191.367 164.762 184.088C164.762 173.917 158.504 166.873 148.368 166.873Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0517536 121.918C3.2239 123.92 6.56239 125.612 10.0902 126.897C10.4229 127.023 10.7556 127.138 11.0883 127.253C20.1171 130.379 29.5934 129.869 35.4788 129.025C62.8465 125.09 64.1199 107.968 91.1606 107.968C95.2964 107.968 99.0536 108.673 102.799 109.798C119.417 114.777 126.003 118.184 143.55 124.752C148.133 126.467 153.709 127.999 158.108 128.343C160.242 128.509 162.692 128.394 164.768 127.981V161.51H156.37V153.06C156.37 152.854 124.041 153.221 121.362 153.244C108.077 153.387 94.3786 151.609 82.3784 145.58C78.2425 143.504 74.21 141.049 69.5865 140.268C63.552 139.242 57.3282 140.246 51.5977 142.236C45.4312 144.37 39.867 147.726 33.5744 149.607C25.4805 152.022 17.249 152.728 8.85116 153.032C8.6045 153.037 8.34637 153.049 8.09971 153.055V161.487H0.0517536C0.0517536 161.487 0.000127357 121.89 0.0517536 121.918Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.40547 14.4956H6.91221V13.0271H0.160645V14.4956H2.67312V21.3275H4.40547V14.4956Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9009 13.0271H13.3999L11.9142 19.5722H11.8912L10.3941 13.0271H7.85864V21.3275H9.47626V14.3923H9.49921L11.0193 21.3275H12.7172L14.2603 14.3923H14.289V21.3275H15.9009V13.0271Z" fill="white"/>
  <path d="M64.59 36.0925L111.449 13.1188L106.402 2.86237L99.6901 6.16072C96.2254 3.83754 85.252 0.791589 62.1636 2.62719L60.9819 0.372843C58.1769 0.00572292 51.804 1.23902 48.4024 2.73044C48.3507 2.75339 48.2991 2.77633 48.2475 2.80501C48.1959 2.82796 48.1442 2.85664 48.0983 2.87958C44.8287 4.65782 39.9586 8.94853 38.536 11.3864L39.5972 13.7039C24.0233 30.8438 19.7211 41.3927 19.4343 45.5573L12.7229 48.8441L17.7536 59.1177L64.59 36.0982V36.0925Z" fill="white"/>
  <path d="M156.485 78.6554C156.496 78.8792 156.502 79.1086 156.508 79.3323C156.536 81.0016 156.416 82.6766 156.135 84.3171C155.785 86.3191 155.177 88.4587 153.795 90.0132C153.049 90.8507 152.143 91.4071 151.035 91.5964C149.022 91.9463 146.98 92.0611 144.944 92.1471C142.144 92.2676 139.311 92.2905 136.534 91.8832C134.848 91.6366 132.02 91.2006 131.331 89.3249C130.93 80.514 128.767 70.7968 123.134 63.8272C120.192 60.1905 115.924 58.4983 112.482 55.4466C108.799 52.1769 105.438 47.7772 105.432 42.6375L115.562 37.7331L107.893 21.9641L27.0694 61.5729L34.647 77.4967L44.3297 72.5693C45.5401 75.1449 49.7218 85.2234 41.1175 88.8946C38.1748 90.2599 22.641 96.0707 8.21434 87.5294V78.6153L0.160645 78.6554L0.177853 113.365C3.45325 115.689 7.31948 117.352 11.0882 118.66C20.117 121.786 29.5933 121.276 35.4787 120.433C62.8464 116.497 64.1198 99.3748 91.1605 99.3748C95.2963 99.3748 99.0536 100.08 102.799 101.199C119.417 106.178 126.002 109.585 143.55 116.159C148.133 117.868 153.709 119.4 158.108 119.75C160.242 119.916 162.691 119.796 164.768 119.389C164.768 119.389 164.768 119.371 164.877 119.36V78.6612H156.479L156.485 78.6554Z" fill="white"/>
  </g>
  </svg>

  

    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="103" viewBox="0 0 85 103" fill="none" class="splash-image breathing" style="display: none;">
      <mask id="mask0_1205_2947" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="85" height="103">
        <path d="M85 0H0V102.919H85V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_1205_2947)">
        <path d="M64.0425 85.8072C59.4057 85.8072 57.7319 89.7892 57.7319 92.1448V102.895H62.8655V97.7769L65.1958 97.7828V102.892H70.3293V92.1418C70.3293 89.7892 68.6822 85.8042 64.0425 85.8042M65.1958 93.5085H62.8655V92.0208C62.8655 91.1588 63.3357 90.6541 64.0188 90.6541C64.7019 90.6541 65.1958 91.1588 65.1958 92.0208V93.5085Z" fill="#076FB3"/>
        <path d="M20.1232 98.5707C19.3839 98.5707 18.7836 97.9715 18.7836 97.2336C18.7836 96.4956 19.3839 95.8964 20.1232 95.8964H23.9142V94.3555L23.9172 92.546H20.1232C19.3839 92.546 18.7836 91.9468 18.7836 91.2088C18.7836 90.4709 19.3839 89.8717 20.1232 89.8717H23.929V85.8601H18.9699C16.0512 85.8601 13.6441 86.8578 13.6441 90.7661C13.6441 92.6523 14.7382 93.5379 15.9566 94.3526H15.9536C14.7323 95.1732 13.6382 96.0558 13.6382 97.9449C13.6382 101.85 16.0276 102.889 18.9462 102.889H23.9083L23.929 98.5737H20.1261L20.1232 98.5707Z" fill="#076FB3"/>
        <path d="M55.5791 87.2387C54.7807 86.2941 53.6304 85.8218 52.1311 85.8218C51.1848 85.8218 50.3923 86.0018 49.7477 86.3649C49.106 86.728 48.4998 87.3302 47.9379 88.1774C47.0182 86.607 45.7023 85.8218 43.9901 85.8218C42.547 85.8218 41.376 86.2941 40.4682 87.2387C39.5633 88.1833 39.1108 89.3994 39.1108 90.8871V102.895H43.863V91.3771C43.863 90.8783 44.271 90.4709 44.7708 90.4709C45.2706 90.4709 45.6786 90.8783 45.6786 91.3771V102.895H50.1972V91.3771C50.1972 90.8783 50.6052 90.4709 51.105 90.4709C51.6048 90.4709 52.0128 90.8783 52.0128 91.3771V102.895H56.7738V91.3152C56.7738 89.5411 56.3746 88.1833 55.5762 87.2387" fill="#076FB3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0269 93.7771C11.8046 92.8886 12.0885 92.0709 12.0885 90.9197C12.0885 87.6786 9.21414 85.8101 6.29545 85.8101C2.48074 85.8101 0.0322266 88.0653 0.0322266 91.4274V102.898H5.015V91.6754V91.6193C5.015 90.9876 5.52955 90.474 6.16533 90.474H6.22152C6.88096 90.4799 7.41029 91.0141 7.41029 91.6695C7.41029 92.3248 6.87505 92.865 6.21265 92.865C6.10915 92.865 6.04113 92.865 5.92285 92.8443V95.8965H6.51723C7.25947 95.8965 7.85682 96.4957 7.85682 97.2337C7.85682 97.9716 7.25947 98.5709 6.51723 98.5709H5.92285V102.895H7.98989C10.814 102.919 13.1235 101.145 13.1235 97.8801C13.1235 96.0087 12.4167 94.642 11.0269 93.7771Z" fill="#076FB3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.9527 94.4236C36.9818 93.6089 37.5939 92.2156 37.5939 90.9434C37.5939 87.7996 34.3854 85.8042 31.443 85.8042C27.306 85.8042 24.9019 88.1096 24.9019 92.2865V102.892H30.1715V91.6695V91.6134C30.1715 90.9817 30.686 90.4681 31.3188 90.4681H31.378C32.0374 90.474 32.5668 91.0053 32.5668 91.6636C32.5668 92.3219 32.0315 92.8591 31.3691 92.8591C31.2656 92.8591 31.1828 92.8591 31.0793 92.8443V97.0478C32.2444 97.0773 32.4958 97.5348 32.4958 98.6949V102.892H37.8364V97.8536C37.8364 96.1711 37.3455 94.9254 35.9497 94.4177" fill="#076FB3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M76.4863 85.8721H71.2817V102.895H76.4153V90.6718C78.8875 90.6718 79.7125 92.5905 79.7125 94.273C79.7125 96.1917 78.9466 97.6499 77.5864 98.01V102.848C82.2202 102.603 84.9378 98.4764 84.9378 94.7306C84.9378 89.4969 81.7116 85.8721 76.4863 85.8721Z" fill="#076FB3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.026477 62.7384C1.66177 63.7686 3.38283 64.6394 5.20147 65.3006C5.37298 65.3656 5.5445 65.4246 5.71601 65.4837C10.3705 67.0924 15.2557 66.8297 18.2898 66.3958C32.3983 64.3708 33.0548 55.5596 46.9947 55.5596C49.1268 55.5596 51.0637 55.9226 52.9947 56.5012C61.5616 59.0634 64.9564 60.8168 74.0023 64.1967C76.365 65.0793 79.2394 65.8674 81.5075 66.0445C82.6075 66.1301 83.8702 66.0711 84.9407 65.8585V83.1121H80.6115V78.764C80.6115 78.6577 63.945 78.8466 62.564 78.8585C55.7153 78.9323 48.6537 78.0172 42.4673 74.9148C40.3352 73.8462 38.2564 72.5828 35.8729 72.1814C32.762 71.653 29.5535 72.1696 26.5993 73.1939C23.4204 74.292 20.552 76.0188 17.308 76.987C13.1355 78.2297 8.89198 78.5928 4.56273 78.7492C4.43557 78.7522 4.3025 78.7581 4.17534 78.761V83.1003H0.026477C0.026477 83.1003 -0.000137256 62.7237 0.026477 62.7384Z" fill="#1E96D4"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.2708 7.45929H3.56307V6.70361H0.0825195V7.45929H1.37775V10.9749H2.2708V7.45929Z" fill="#076FB3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.19718 6.70361H6.90787L6.14197 10.0717H6.13014L5.35833 6.70361H4.05127V10.9749H4.88518V7.40615H4.89701L5.68065 10.9749H6.55597L7.35144 7.40615H7.36622V10.9749H8.19718V6.70361Z" fill="#076FB3"/>
        <path d="M33.297 18.573L57.4539 6.75082L54.8516 1.47293L51.3918 3.17024C49.6057 1.97474 43.9487 0.407315 32.0462 1.3519L31.437 0.191831C29.991 0.00291277 26.7056 0.637559 24.952 1.40504C24.9254 1.41685 24.8988 1.42865 24.8722 1.44341C24.8455 1.45522 24.8189 1.46998 24.7953 1.48179C23.1097 2.39686 20.5991 4.60483 19.8657 5.85937L20.4128 7.05191C12.3842 15.872 10.1663 21.3005 10.0184 23.4435L6.55859 25.1349L9.152 30.4216L33.297 18.5759V18.573Z" fill="#076FB3"/>
        <path d="M80.6705 40.4755C80.6764 40.5907 80.6793 40.7087 80.6823 40.8239C80.6971 41.6828 80.635 42.5448 80.4901 43.389C80.3097 44.4192 79.9962 45.5202 79.2836 46.3202C78.8991 46.7512 78.4319 47.0375 77.8612 47.1349C76.8232 47.315 75.7705 47.374 74.7207 47.4183C73.2776 47.4803 71.8168 47.4921 70.3855 47.2825C69.5161 47.1556 68.0583 46.9312 67.7034 45.966C67.4964 41.4319 66.3816 36.4315 63.4777 32.845C61.9606 30.9736 59.7605 30.1028 57.9863 28.5324C56.0878 26.8498 54.3549 24.5858 54.3519 21.9409L59.5742 19.4171L55.6205 11.3025L13.9545 31.685L17.8608 39.8793L22.8525 37.3436C23.4764 38.669 25.6322 43.8554 21.1965 45.7446C19.6795 46.4471 11.6715 49.4373 4.23434 45.042V40.4549L0.0825195 40.4755L0.0913909 58.3371C1.77992 59.5326 3.77303 60.3887 5.71587 61.0617C10.3704 62.6704 15.2556 62.4077 18.2896 61.9738C32.3981 59.9488 33.0546 51.1376 46.9946 51.1376C49.1267 51.1376 51.0636 51.5007 52.9946 52.0763C61.5614 54.6385 64.9562 56.3919 74.0021 59.7747C76.3649 60.6543 79.2392 61.4425 81.5073 61.6225C82.6074 61.7081 83.8701 61.6462 84.9406 61.4366C84.9406 61.4366 84.9406 61.4277 84.9968 61.4218V40.4785H80.6675L80.6705 40.4755Z" fill="#076FB3"/>
      </g>
    </svg>
    </div>



    <div class="bubble bubble--1"></div>
    <div class="bubble bubble--2"></div>
    <div class="bubble bubble--3"></div>
    <div class="bubble bubble--4"></div>
    <div class="bubble bubble--5"></div>
    <div class="bubble bubble--6"></div>
    <div class="bubble bubble--7"></div>
    <div class="bubble bubble--8"></div>
    <div class="bubble bubble--9"></div>
    <div class="bubble bubble--10"></div>
    <div class="bubble bubble--11"></div>
    <div class="bubble bubble--12"></div>


  </div>

  <small class="splash-text" *ngIf="version" style="position: fixed; bottom: 0;  left: 0;  right: 0;  color: white;  text-align: center;  padding: 15px;">{{'Version' | translate}}: {{version}}</small>
 
</div>