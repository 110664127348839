import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../../services/analytics.service';
import { Router } from '@angular/router';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';


import '../../../../capacitor-plugin-install/src';
import { Plugins } from '@capacitor/core';
const { ApkInstaller } = Plugins;

import { registerPlugin } from '@capacitor/core';



@Component({
  selector: 'app-update-app-pop-up',
  templateUrl: './update-app-pop-up.component.html',
  styleUrls: ['./update-app-pop-up.component.scss'],
})
export class UpdateAppPopUpComponent implements OnInit {
  @Input() update_url!: string;
  @Input() av!: string;
  @Input() dv!: string;
  @Input() countryName!: string;

  isLoading = false;
  downloadProgress: number = 0; // Progress percentage

  constructor(
    private modalController: ModalController,
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {}

  ngOnInit() {}

  async closeModalController() {
    this.modalController.dismiss();
  }

  async UpdateNow() {
    this.downloadAndInstallApk(this.update_url);
  }




  async downloadAndInstallApk(url: string) {
    const fileName = 'Bermad-Connect.apk';
    this.isLoading = true;
    this.downloadProgress = 0; // Reset progress
    try {
      const permissionGranted = await this.requestPermissions();
      if (permissionGranted) {
        const fileUri = await this.downloadApk(url, fileName);
        if (fileUri) {
          await this.installApkFile(fileUri);
        }
      } else {
        console.error('Permissions were not granted.');
      }
    } catch (error) {
      console.error('Error downloading or installing APK:', error);
    } finally {
      this.isLoading = false;
    }
  }

  async installApkFile(fileUri: string): Promise<void> {
    try {

      /*

      ApkInstaller['installApk']({ apkFilePath: fileUri }).then(() => {
        console.log('APK installation started');
      }).catch((error: any) => {
        console.error('APK installation failed', error);
      });
*/

   
      
    // Convert the file path to a content URI for Android 7.0 (API level 24) and higher
    //const path = fileUri.replace('file://', '');
   // const apkPath = Capacitor.convertFileSrc(path);

    
    // Use the AppLauncher to open the APK file
    const appLauncherResult = await AppLauncher.openUrl({ url: fileUri});
    console.log('APK installation initiated:', JSON.stringify(appLauncherResult));
    
    if (!appLauncherResult.completed) {
    // The URL could not be opened; guide the user to install manually
    alert('Please install the APK manually by locating the downloaded file using a file manager.');
    }
    
    } catch (error) {
    console.error('Error opening APK:', error);
    alert('Failed to open the APK. Please ensure that you have allowed unknown sources.');
    }
    }


    
    // Utility function to convert Blob to string
    private async blobToString(blob: Blob): Promise<string> {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
              resolve(reader.result as string);
          };
          reader.onerror = reject;
          reader.readAsText(blob);
      });
    }
    
    
    
    
    async downloadApk(url: string, fileName: string): Promise<string | undefined> {

      const tempFilePath = fileName; // Temporary file path for streaming
      let reader: ReadableStreamDefaultReader<Uint8Array> | undefined;


      const finalUri = (await Filesystem.getUri({
          path: fileName,
          directory: Directory.External,
      })).uri;

      console.log('APK downloaded to:', finalUri);

      // Hide the progress bar
      this.downloadProgress = 0;

      return finalUri;
      /*
    
      try {
          const response = await fetch(url, { mode: 'cors' });
          if (!response.ok) {
              throw new Error(`Failed to download APK. Status: ${response.status}`);
          }
    
          const contentLength = response.headers.get('Content-Length');
          const totalBytes = contentLength ? parseInt(contentLength, 10) : 0;
          let bytesReceived = 0;
          const startTime = performance.now();
    
          reader = response.body?.getReader();
          if (!reader) {
              throw new Error('Failed to get response body reader.');
          }
    
          const chunks: Uint8Array[] = [];
    
          while (true) {
              const { done, value } = await reader.read();
              if (done) {
                  break;
              }
    
              if (value) {
                  chunks.push(value);
                  bytesReceived += value.length;
    
                  // Calculate download speed and time remaining
                  const elapsedTime = (performance.now() - startTime) / 1000; // in seconds
                  const speedInKBps = bytesReceived / 1024 / elapsedTime;
                  const timeRemaining = (totalBytes - bytesReceived) / (speedInKBps * 1024); // in seconds
                  const timeRemainingFormatted = new Date(timeRemaining * 1000).toISOString().substr(11, 8);
    
                  // Update progress
                  const progress = Math.min((bytesReceived / totalBytes) * 100, 100);
                  this.downloadProgress = totalBytes ? Math.round((bytesReceived / totalBytes) * 100) : 0;
    
                  console.log(`Download progress: ${progress.toFixed(2)}%, Speed: ${speedInKBps.toFixed(2)} KB/s, Time remaining: ${timeRemainingFormatted}`);
              }
          }
    
          // Concatenate all chunks into a single Uint8Array
          const completeArray = new Uint8Array(bytesReceived);
          let position = 0;
          for (const chunk of chunks) {
              completeArray.set(chunk, position);
              position += chunk.length;
          }
    
          // Convert the Uint8Array to a Blob
          const blob = new Blob([completeArray], { type: 'application/vnd.android.package-archive' });
    
          // Write the Blob to the file
          await Filesystem.writeFile({
              path: tempFilePath,
              data: blob,
              directory: Directory.External,
          });
    
          const finalUri = (await Filesystem.getUri({
              path: fileName,
              directory: Directory.External,
          })).uri;
    
          console.log('APK downloaded to:', finalUri);
    
          // Hide the progress bar
          this.downloadProgress = 0;
    
          return finalUri;
      } catch (error) {
          console.error('Error downloading APK:', error);
          // Cleanup temp file in case of error
          await Filesystem.deleteFile({
              path: tempFilePath,
              directory: Directory.External,
          });
          return undefined;
      } finally {
          // Close the reader to free up resources
          if (reader) {
              reader.cancel();
          }
      }*/
    }
    
    
    
    

    


    /*
  async downloadApk(url: string, fileName: string): Promise<string | undefined> {
    const tempFilePath = fileName + '.tmp'; // Temporary file path for streaming
    try {
      // Create or truncate the temporary file
      await Filesystem.writeFile({
        path: tempFilePath,
        data: '',
        directory: Directory.External,
        encoding: Encoding.UTF8,
      });

      const response = await fetch(url, { mode: 'cors' });
      if (!response.ok) {
        throw new Error(`Failed to download APK. Status: ${response.status}`);
      }

      const contentLength = response.headers.get('Content-Length');
      const totalBytes = contentLength ? parseInt(contentLength, 10) : 0;
      let bytesReceived = 0;

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Failed to get response body reader.');
      }

      let chunks: Uint8Array[] = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        if (value) {
          chunks.push(value);
          bytesReceived += value.length;
          // Update progress
          this.downloadProgress = totalBytes ? Math.round((bytesReceived / totalBytes) * 100) : 0;
          console.log(`Download progress: ${this.downloadProgress}%`);

          // Write chunk to file
          await Filesystem.appendFile({
            path: tempFilePath,
            data: this.arrayBufferToBase64(value.buffer),
            directory: Directory.External,
          });
        }
      }

      // Rename the temporary file to final file name
      await Filesystem.rename({
        from: tempFilePath,
        to: fileName,
        directory: Directory.External,
      });

      const finalUri = (await Filesystem.getUri({
        path: fileName,
        directory: Directory.External,
      })).uri;

      console.log('APK downloaded to:', finalUri);
      
      return finalUri;
    } catch (error) {
      console.error('Error downloading APK:', error);
      // Cleanup temp file in case of error
      await Filesystem.deleteFile({
        path: tempFilePath,
        directory: Directory.External,
      });
      return undefined;
    }
  }*/

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  async requestPermissions(): Promise<boolean> {
    if (Capacitor.getPlatform() === 'android') {
      try {
        await Filesystem.writeFile({
          path: 'dummy.txt',
          data: btoa('Dummy data'), // Convert dummy data to base64
          directory: Directory.External,
        });
        await Filesystem.deleteFile({
          path: 'dummy.txt',
          directory: Directory.External,
        });
        return true;
      } catch (error) {
        console.warn('Storage permissions check failed:', error);
        alert('Please enable storage permissions for this app in your device settings.');
        return false;
      }
    }
    return true; // Assume permissions are granted on other platforms
  }
}
