import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Auth, authState } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { GeoService } from "../services/geo.service";
import { StorageService } from "../services/storage.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  user$: Observable<any>;
    constructor(private auth: Auth,    private geoService: GeoService,  private storageService: StorageService) { 

      this.user$ = authState(this.auth);
      
    }

    canActivate(): Observable<boolean> {
      return new Observable(observer => {
        this.geoService.get_country().then(async (resolvedCountry) => {
          if (resolvedCountry === 'CN' || resolvedCountry === 'cn' || resolvedCountry === 'China' || resolvedCountry === 'china') {
            // If the country is China, check the session time
            const session_id_expires = await this.storageService.get('session_id_expires'); // Await the resolution of the promise
            if (session_id_expires) {
              const date = new Date();
              const now = date.getTime();
              const sessionExpiresDate = new Date(session_id_expires);
              const sessionExpiresTimestamp = sessionExpiresDate.getTime();
              observer.next(now <= sessionExpiresTimestamp); // If the session has not expired, allow access
            } else {
              observer.next(false); // If there is no session time, deny access
            }
          } else {
            this.user$.subscribe(user => {
              observer.next(!!user);
            });
          }
        }); // This closing parenthesis was missing
      });
    }
}